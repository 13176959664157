<template>
  <div class="px-4 py-2">
    <button @click="toggleChat" class="enterprise-button" :disabled="loading">
      <ion-icon :icon="chatboxEllipsesOutline"></ion-icon>
    </button>

    <div
      id="talkjs-container"
      v-show="chatVisible"
      ref="chatWindowContainer"
      class="chat-window"
      style="height: 600px"
    ></div>
  </div>
</template>

<script>
import { AxertaChat } from "@/utils/axerta";
import { IonButton, IonIcon } from "@ionic/vue";
import { chatboxEllipsesOutline } from "ionicons/icons";

export default {
  name: "TALKJS-BUTTON",

  components: {
    IonButton,
    IonIcon,
  },

  data() {
    return {
      loading: true,
      chatVisible: false,
      session: null,
      me: null,

      chatboxEllipsesOutline,
    };
  },

  computed: {
    conversations() {
      return this.$store.getters.conversations;
    },
    inbox() {
      return this.$store.getters.inbox;
    },
  },
  watch: {
    conversations: {
      handler: function () {
        this.buildInbox();
      },
    },
    inbox: {
      handler: function () {
        this.buildInbox();
      },
    },
  },

  mounted() {
    setTimeout(async () => {
      await this.$nextTick();

      await this.handleCreationTalkjsSession();

      this.loading = false;
    }, 1200);
  },

  methods: {
    toggleChat() {
      this.chatVisible = !this.chatVisible;
      this.$emit("toggle-chat", this.chatVisible); // Emit per notificare il cambiamento
    },
    async buildInbox() {
      if (this.inbox) {
        this.inbox.mount(this.$refs.chatWindowContainer);
        return;
      }

      const inbox = await this.session.createInbox({
        showMessageActions: true,
      });

      const conversations = this.$store.getters.conversations;

      for (const conversation of conversations) {
        inbox.select(conversation);
      }

      inbox.mount(this.$refs.chatWindowContainer);
    },
    async handleCreationTalkjsSession() {
      const loggedUser = this.$store.getters["user/getUserData"];

      try {
        const me = {
          id: "Axerta_CIE_" + loggedUser.id,
          fullname: loggedUser.nome + " " + loggedUser.cognome,
          email: loggedUser.email,
          avatar_url:
            loggedUser.avatar_url ||
            "https://avatar.iran.liara.run/public/" + loggedUser.id,
          welcomeMessage: "Ciao! Sto aspettando il tuo messaggio.",
          role: "CIE",
        };

        const session = await AxertaChat.createSession("tjBGvoRX", me);

        const inbox = session.createInbox({
          showMessageActions: true,
        });
        inbox.mount(this.$refs.chatWindowContainer);

        this.session = session;
      } catch (error) {
        console.error("Errore nella creazione della sessione TalkJS:", error);
      }
    },
  },
};
</script>

<style scoped>
.enterprise-button {
  width: 60px;
  height: 60px;
  background-color: var(--button1-bg-color);
  color: var(--button1-text-color);
  border: 2px solid var(--button1-text-color);
  border-radius: 50% !important;
  padding: 5px;
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 10000;
}

.enterprise-button:disabled {
  background-color: #ccc;
  color: #666;
  border: 2px solid #999;
  cursor: not-allowed;
  box-shadow: none;
}

.enterprise-button:hover:not(:disabled) {
  background-color: var(--button1-text-color);
  color: var(--button1-bg-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chat-window {
  position: fixed;
  bottom: 80px;
  right: 0;
  z-index: 1000;
  width: 750px;
}

#talkjs-container {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
}

@media (max-width: 768px) {
  #talkjs-container {
    width: 100vw;
    height: calc(100vh - 50px);

    padding: 10px;
  }
}
</style>
