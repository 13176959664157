const state = {
  inbox: null,
  conversations: [],
};

const mutations = {
  SET_INBOX(state, inbox) {
    state.inbox = inbox;
  },
  ADD_CONVERSATION(state, conversationId) {
    state.conversations.push(conversationId);
  },
};

const actions = {
  addTalkConversation({ commit }, conversationId) {
    commit("ADD_CONVERSATION", conversationId);
  },
  newInbox({ commit }, inbox) {
    commit("SET_INBOX", inbox);
  },
};

const getters = {
  inbox: (state) => state.inbox,
  conversations: (state) => state.conversations,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
