import { defineStore } from "pinia";
import { ref, computed, watch } from "vue";
import ApiService from "../common/service.api";

export const useGeneralStore = defineStore("general", () => {
  const loading = ref({
    userList: false,
  });

  // Recupera i dati dal localStorage, oppure imposta valori di default
  const _youbeFilter = ref(
    JSON.parse(localStorage.getItem("youbeFilter")) || {
      regione: "all",
      provincia: "all",
      citta: "all",
      agente: null,
    }
  );
  const _userList = ref([
    {
      id: 156,
      nome: "Alberto",
      cognome: " Ferrari ",
      email: "albertoferrariferrari",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 213,
      nome: "Valentina ",
      cognome: "Aceti",
      email: "valentinaaceti",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:50:59.323",
    },
    {
      id: 178,
      nome: "Fabio ",
      cognome: "Aceti ",
      email: "fabioaceti",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:50:21.807",
    },
    {
      id: 187,
      nome: "Luca ",
      cognome: "Aliverti ",
      email: "lucaaliverti",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:48:55.11",
    },
    {
      id: 106,
      nome: "Christian",
      cognome: "Bale",
      email: "cristian@hollywood.it",
      temporaryPassword: "EN$#oWXU",
      avatar_url:
        "https://api-demo.whappy.it/Drive/4d06a7eb-75ce-4a4e-bcbb-e6994611d3aa_download.jpeg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 29,
      id_area: 39,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 121,
      role: null,
      permalink: "cristian@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 170,
      nome: "Cinzia ",
      cognome: "Biasetti ",
      email: "cinziabiasetti",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 174,
      nome: "Donato ",
      cognome: "Boccasavia ",
      email: "donatoboccasavia",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-28T09:50:52.407",
    },
    {
      id: 195,
      nome: "Nicoletta ",
      cognome: "Boldrini ",
      email: "nicolettaboldrini",
      temporaryPassword: "",
      avatar_url:
        "https://api-demo.whappy.it/Drive/160f4a13-52b8-4506-bba5-7cf0639c856e_image.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:48:32.743",
    },
    {
      id: 212,
      nome: "Teresa ",
      cognome: "Bosisio",
      email: "teresabosisio",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 172,
      nome: "Domenico ",
      cognome: "Bosisio ",
      email: "domenicobosisio",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:51:49.637",
    },
    {
      id: 192,
      nome: "Mattia ",
      cognome: "Brambilla ",
      email: "mattiabrambilla",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:48:52.217",
    },
    {
      id: 184,
      nome: "Graziella ",
      cognome: "Brambilla ",
      email: "graziellabrambilla",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:49:33.94",
    },
    {
      id: 243,
      nome: "Kenneth",
      cognome: "Branagh",
      email: "kenneth.branagh@automotive.spa",
      temporaryPassword: "ka4owwYc",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/ad66ac56-9495-4df9-a460-ec6e1d5204b2_50_mejores_actores_vivos_gq_870832557.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 41,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 242,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 124,
      role: null,
      permalink: "kenneth.branagh@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT":
          "L'accettatore in un'officina Mercedes ha la missione di garantire un'accoglienza professionale ed efficiente per tutti i clienti, assicurando che le loro esigenze siano soddisfatte e che le operazioni di manutenzione e riparazione siano gestite con la massima precisione e trasparenza, in linea con gli elevati standard del marchio Mercedes-Benz.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT":
          "L'accettatore è responsabile di accogliere i clienti, identificare le loro esigenze e preparare le richieste di intervento. Deve assicurarsi che le informazioni siano correttamente trasmesse ai tecnici, coordinare le tempistiche delle lavorazioni e garantire una comunicazione continua e chiara con il cliente, mantenendo al contempo un elevato livello di soddisfazione.\n\nCompiti\n- Accogliere i clienti e ascoltare le loro richieste di servizio.\n- Redigere e gestire le richieste di lavoro in modo accurato.\n- Coordinare con il team tecnico per l'assegnazione dei compiti e il rispetto delle scadenze.\n- Tenere i clienti aggiornati sullo stato di avanzamento dei lavori.\n- Gestire le pratiche amministrative correlate agli interventi.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT":
          "- Prenotazione degli appuntamenti e gestione del flusso di lavoro giornaliero.\n- Inserimento e aggiornamento delle informazioni sui clienti nel sistema gestionale.\n- Controllo e verifica dei veicoli al momento della consegna e del ritiro.\n- Supervisione del processo di fatturazione e riscossione dei pagamenti.\n- Collaborazione con il team per migliorare continuamente l'esperienza del cliente.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 244,
      nome: "Jeff",
      cognome: "Bridges",
      email: "jeff.bridges@automotive.spa",
      temporaryPassword: "ao-weAkc",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/31a4d294-5ff4-4336-8849-2a219edbb680_50_mejores_actores_vivos_gq_58892403.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 41,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 242,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 124,
      role: null,
      permalink: "jeff.bridges@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT":
          "L'accettatore in un'officina Mercedes ha la missione di garantire un'accoglienza professionale ed efficiente per tutti i clienti, assicurando che le loro esigenze siano soddisfatte e che le operazioni di manutenzione e riparazione siano gestite con la massima precisione e trasparenza, in linea con gli elevati standard del marchio Mercedes-Benz.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT":
          "L'accettatore è responsabile di accogliere i clienti, identificare le loro esigenze e preparare le richieste di intervento. Deve assicurarsi che le informazioni siano correttamente trasmesse ai tecnici, coordinare le tempistiche delle lavorazioni e garantire una comunicazione continua e chiara con il cliente, mantenendo al contempo un elevato livello di soddisfazione.\n\nCompiti\n- Accogliere i clienti e ascoltare le loro richieste di servizio.\n- Redigere e gestire le richieste di lavoro in modo accurato.\n- Coordinare con il team tecnico per l'assegnazione dei compiti e il rispetto delle scadenze.\n- Tenere i clienti aggiornati sullo stato di avanzamento dei lavori.\n- Gestire le pratiche amministrative correlate agli interventi.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT":
          "- Prenotazione degli appuntamenti e gestione del flusso di lavoro giornaliero.\n- Inserimento e aggiornamento delle informazioni sui clienti nel sistema gestionale.\n- Controllo e verifica dei veicoli al momento della consegna e del ritiro.\n- Supervisione del processo di fatturazione e riscossione dei pagamenti.\n- Collaborazione con il team per migliorare continuamente l'esperienza del cliente.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 108,
      nome: "Jeff",
      cognome: "Bridges",
      email: "jeff@hollywood.it",
      temporaryPassword: "ZhXcP8Oq",
      avatar_url:
        "https://api-demo.whappy.it/Drive/f62ac76d-603c-4e5b-8482-3ff20afaade8_R.jpeg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 29,
      id_area: 38,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 119,
      role: null,
      permalink: "jeff@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 202,
      nome: "Roberta ",
      cognome: "Brigatti",
      email: "robertabrigatti",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 209,
      nome: "Simonetta ",
      cognome: "Cambianica ",
      email: "simonettacambianica",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 173,
      nome: "Donatella  ",
      cognome: "Cambosu",
      email: "donatellacambosu",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:51:04.05",
    },
    {
      id: 210,
      nome: "Sonia ",
      cognome: "Carella",
      email: "soniacarella",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:48:54.47",
    },
    {
      id: 189,
      nome: "Mara ",
      cognome: "Carizzoni ",
      email: "maracarizzoni",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:48:42.917",
    },
    {
      id: 163,
      nome: "Annamaria ",
      cognome: "Carsana",
      email: "annamariacarsana",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 105,
      nome: "George",
      cognome: "Clooney",
      email: "george@hollywood.it",
      temporaryPassword: "nK5IIfTx",
      avatar_url:
        "https://api-demo.whappy.it/Drive/7096e0ee-ca9d-4983-8a8d-a649b8f258ff_gettyimages-969403912.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 29,
      id_area: 37,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 119,
      role: null,
      permalink: "george@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 230,
      nome: "George",
      cognome: "Clooney",
      email: "george.clooney@automotive.spa",
      temporaryPassword: "MN7uqm8e",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/bc9e4b59-5eeb-44aa-aed5-bb56c9a39956_Schermata%202024-08-28%20alle%2001.20.48.png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 40,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 229,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 125,
      role: null,
      permalink: "george.clooney@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 246,
      nome: "Bryan",
      cognome: "Cranston",
      email: "bryan.cranston@automotive.spa",
      temporaryPassword: "uS77@fea",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/a8bfb67c-1972-4812-a914-6c5fe74e0565_50_mejores_actores_vivos_gq_311624035.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 43,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 245,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 124,
      role: null,
      permalink: "bryan.cranston@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT":
          "L'accettatore in un'officina Mercedes ha la missione di garantire un'accoglienza professionale ed efficiente per tutti i clienti, assicurando che le loro esigenze siano soddisfatte e che le operazioni di manutenzione e riparazione siano gestite con la massima precisione e trasparenza, in linea con gli elevati standard del marchio Mercedes-Benz.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT":
          "L'accettatore è responsabile di accogliere i clienti, identificare le loro esigenze e preparare le richieste di intervento. Deve assicurarsi che le informazioni siano correttamente trasmesse ai tecnici, coordinare le tempistiche delle lavorazioni e garantire una comunicazione continua e chiara con il cliente, mantenendo al contempo un elevato livello di soddisfazione.\n\nCompiti\n- Accogliere i clienti e ascoltare le loro richieste di servizio.\n- Redigere e gestire le richieste di lavoro in modo accurato.\n- Coordinare con il team tecnico per l'assegnazione dei compiti e il rispetto delle scadenze.\n- Tenere i clienti aggiornati sullo stato di avanzamento dei lavori.\n- Gestire le pratiche amministrative correlate agli interventi.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT":
          "- Prenotazione degli appuntamenti e gestione del flusso di lavoro giornaliero.\n- Inserimento e aggiornamento delle informazioni sui clienti nel sistema gestionale.\n- Controllo e verifica dei veicoli al momento della consegna e del ritiro.\n- Supervisione del processo di fatturazione e riscossione dei pagamenti.\n- Collaborazione con il team per migliorare continuamente l'esperienza del cliente.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 193,
      nome: "Monica ",
      cognome: "Cremaschi ",
      email: "monicacremaschi",
      temporaryPassword: "",
      avatar_url:
        "https://api-demo.whappy.it/Drive/af801f7e-8df9-4e7a-b60c-d6e62083547f_image.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:49:17.667",
    },
    {
      id: 107,
      nome: "Tom",
      cognome: "Cruise",
      email: "tom@hollywood.it",
      temporaryPassword: "rLnqVD36",
      avatar_url:
        "https://api-demo.whappy.it/Drive/aac2c155-61a3-4999-8272-c28e965dd852_gettyimages-693134468.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 29,
      id_area: 39,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 119,
      role: null,
      permalink: "tom@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 110,
      nome: "Penelope",
      cognome: "Cruz",
      email: "penelope@hollywood.it",
      temporaryPassword: "P6YlU0qy",
      avatar_url:
        "https://api-demo.whappy.it/Drive/4e54a84d-25ac-451a-98fb-e5ae0ed2bddd_sd-aspect-1500651423-penelope-cruz-curiosita.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 29,
      id_area: 38,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 119,
      role: null,
      permalink: "penelope@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 229,
      nome: "Penelope",
      cognome: "Cruz",
      email: "penelope.CruzZP@automotive.spa",
      temporaryPassword: "*wT09VC!",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/dde0a545-40c8-42f6-8a06-d718eb31f200_Schermata%202024-08-24%20alle%2017.26.29.png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 40,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 236,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 124,
      role: null,
      permalink: "penelope.CruzZP@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT":
          "L'accettatore in un'officina Mercedes ha la missione di garantire un'accoglienza professionale ed efficiente per tutti i clienti, assicurando che le loro esigenze siano soddisfatte e che le operazioni di manutenzione e riparazione siano gestite con la massima precisione e trasparenza, in linea con gli elevati standard del marchio Mercedes-Benz.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT":
          "L'accettatore è responsabile di accogliere i clienti, identificare le loro esigenze e preparare le richieste di intervento. Deve assicurarsi che le informazioni siano correttamente trasmesse ai tecnici, coordinare le tempistiche delle lavorazioni e garantire una comunicazione continua e chiara con il cliente, mantenendo al contempo un elevato livello di soddisfazione.\n\nCompiti\n- Accogliere i clienti e ascoltare le loro richieste di servizio.\n- Redigere e gestire le richieste di lavoro in modo accurato.\n- Coordinare con il team tecnico per l'assegnazione dei compiti e il rispetto delle scadenze.\n- Tenere i clienti aggiornati sullo stato di avanzamento dei lavori.\n- Gestire le pratiche amministrative correlate agli interventi.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT":
          "- Prenotazione degli appuntamenti e gestione del flusso di lavoro giornaliero.\n- Inserimento e aggiornamento delle informazioni sui clienti nel sistema gestionale.\n- Controllo e verifica dei veicoli al momento della consegna e del ritiro.\n- Supervisione del processo di fatturazione e riscossione dei pagamenti.\n- Collaborazione con il team per migliorare continuamente l'esperienza del cliente.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2024-09-26T19:42:53.95",
    },
    {
      id: 241,
      nome: "Matt",
      cognome: "Damon",
      email: "matt.damon@automotive.spa",
      temporaryPassword: "qU#ThBEW",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/e9c15714-5370-4d6c-879f-ac090a568588_50_mejores_actores_vivos_gq_731461606.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 40,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 236,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 124,
      role: null,
      permalink: "matt.damon@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT":
          "L'accettatore in un'officina Mercedes ha la missione di garantire un'accoglienza professionale ed efficiente per tutti i clienti, assicurando che le loro esigenze siano soddisfatte e che le operazioni di manutenzione e riparazione siano gestite con la massima precisione e trasparenza, in linea con gli elevati standard del marchio Mercedes-Benz.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT":
          "L'accettatore è responsabile di accogliere i clienti, identificare le loro esigenze e preparare le richieste di intervento. Deve assicurarsi che le informazioni siano correttamente trasmesse ai tecnici, coordinare le tempistiche delle lavorazioni e garantire una comunicazione continua e chiara con il cliente, mantenendo al contempo un elevato livello di soddisfazione.\n\nCompiti\n- Accogliere i clienti e ascoltare le loro richieste di servizio.\n- Redigere e gestire le richieste di lavoro in modo accurato.\n- Coordinare con il team tecnico per l'assegnazione dei compiti e il rispetto delle scadenze.\n- Tenere i clienti aggiornati sullo stato di avanzamento dei lavori.\n- Gestire le pratiche amministrative correlate agli interventi.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT":
          "- Prenotazione degli appuntamenti e gestione del flusso di lavoro giornaliero.\n- Inserimento e aggiornamento delle informazioni sui clienti nel sistema gestionale.\n- Controllo e verifica dei veicoli al momento della consegna e del ritiro.\n- Supervisione del processo di fatturazione e riscossione dei pagamenti.\n- Collaborazione con il team per migliorare continuamente l'esperienza del cliente.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2024-09-09T18:20:15.04",
    },
    {
      id: 124,
      nome: "Rosario",
      cognome: "Dawson",
      email: "rosario@hollywood.it",
      temporaryPassword: "yOC7LalN",
      avatar_url:
        "https://api-demo.whappy.it/Drive/5b8016a6-2c69-4eb4-a72e-96bff01b7ae5_OIP%20(4).jpeg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 29,
      id_area: 37,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 119,
      role: null,
      permalink: "rosario@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 233,
      nome: "Daniel",
      cognome: "Day-lewis",
      email: "daniel.daylewis@automotive.spa",
      temporaryPassword: "rN$sIm2F",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/8ee5bc50-07fe-4ff7-bbc4-0f6a97319ecf_50_mejores_actores_vivos_gq_301428991.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 42,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 232,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 124,
      role: null,
      permalink: "daniel.daylewis@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT":
          "L'accettatore in un'officina Mercedes ha la missione di garantire un'accoglienza professionale ed efficiente per tutti i clienti, assicurando che le loro esigenze siano soddisfatte e che le operazioni di manutenzione e riparazione siano gestite con la massima precisione e trasparenza, in linea con gli elevati standard del marchio Mercedes-Benz.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT":
          "L'accettatore è responsabile di accogliere i clienti, identificare le loro esigenze e preparare le richieste di intervento. Deve assicurarsi che le informazioni siano correttamente trasmesse ai tecnici, coordinare le tempistiche delle lavorazioni e garantire una comunicazione continua e chiara con il cliente, mantenendo al contempo un elevato livello di soddisfazione.\n\nCompiti\n- Accogliere i clienti e ascoltare le loro richieste di servizio.\n- Redigere e gestire le richieste di lavoro in modo accurato.\n- Coordinare con il team tecnico per l'assegnazione dei compiti e il rispetto delle scadenze.\n- Tenere i clienti aggiornati sullo stato di avanzamento dei lavori.\n- Gestire le pratiche amministrative correlate agli interventi.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT":
          "- Prenotazione degli appuntamenti e gestione del flusso di lavoro giornaliero.\n- Inserimento e aggiornamento delle informazioni sui clienti nel sistema gestionale.\n- Controllo e verifica dei veicoli al momento della consegna e del ritiro.\n- Supervisione del processo di fatturazione e riscossione dei pagamenti.\n- Collaborazione con il team per migliorare continuamente l'esperienza del cliente.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 232,
      nome: "Robert",
      cognome: "De Niro",
      email: "robert.deniro@automotive.spa",
      temporaryPassword: "SXpiZJDK",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/b9d0ffc2-219c-4eca-abb8-42002c963c68_50_mejores_actores_vivos_gq_90154527.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 42,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 228,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 126,
      role: null,
      permalink: "robert.deniro@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 155,
      nome: "Alberto ",
      cognome: "Del Pero ",
      email: "albertodelpero",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:49:13.287",
    },
    {
      id: 115,
      nome: "Robert",
      cognome: "Deniro",
      email: "robert@hollywood.it",
      temporaryPassword: "z7!UzY0L",
      avatar_url:
        "https://api-demo.whappy.it/Drive/7d6e0e3c-79e7-4b0c-b10f-ee89b26fdd4d_OIP%20(1).jpeg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 28,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 105,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 112,
      role: null,
      permalink: "robert@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 248,
      nome: "Johnny",
      cognome: "Depp",
      email: "johnny.depp@automotive.spa",
      temporaryPassword: "?2r$CQrO",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/9f264d87-6993-4a05-9be3-a7777e69ab11_Schermata%202024-08-28%20alle%2001.22.43.png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 44,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 229,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 125,
      role: null,
      permalink: "johnny.depp@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 237,
      nome: "Leonardo",
      cognome: "Di Caprio",
      email: "leonardo.dicaprio@automotive.spa",
      temporaryPassword: "JWCdup6P",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/7ab75f5e-c04e-43fb-8e1a-3b99afa0939a_50_mejores_actores_vivos_gq_660511961.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 40,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 225,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 129,
      role: null,
      permalink: "leonardo.dicaprio@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2024-08-26T19:12:21.83",
    },
    {
      id: 104,
      nome: "Robert",
      cognome: "Downey",
      email: "robert@hollywood.it",
      temporaryPassword: "L-P0AEy2",
      avatar_url:
        "https://api-demo.whappy.it/Drive/13e6cbf4-f4f6-4431-a3c9-96dd48812154_Schermata%202022-07-04%20alle%2018.49.48.png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 18,
      id_area: 15,
      id_department: 9,
      id_cluster: 7,
      company: null,
      id_parent: 117,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 88,
      role: null,
      permalink: "robert@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 235,
      nome: "Robert",
      cognome: "Duvall",
      email: "robert.duvall@automotive.spa",
      temporaryPassword: "Dl55X4i@",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/5442f196-5af1-4119-b4b6-2feed1020273_50_mejores_actores_vivos_gq_813524090.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 42,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 232,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 124,
      role: null,
      permalink: "robert.duvall@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT":
          "L'accettatore in un'officina Mercedes ha la missione di garantire un'accoglienza professionale ed efficiente per tutti i clienti, assicurando che le loro esigenze siano soddisfatte e che le operazioni di manutenzione e riparazione siano gestite con la massima precisione e trasparenza, in linea con gli elevati standard del marchio Mercedes-Benz.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT":
          "L'accettatore è responsabile di accogliere i clienti, identificare le loro esigenze e preparare le richieste di intervento. Deve assicurarsi che le informazioni siano correttamente trasmesse ai tecnici, coordinare le tempistiche delle lavorazioni e garantire una comunicazione continua e chiara con il cliente, mantenendo al contempo un elevato livello di soddisfazione.\n\nCompiti\n- Accogliere i clienti e ascoltare le loro richieste di servizio.\n- Redigere e gestire le richieste di lavoro in modo accurato.\n- Coordinare con il team tecnico per l'assegnazione dei compiti e il rispetto delle scadenze.\n- Tenere i clienti aggiornati sullo stato di avanzamento dei lavori.\n- Gestire le pratiche amministrative correlate agli interventi.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT":
          "- Prenotazione degli appuntamenti e gestione del flusso di lavoro giornaliero.\n- Inserimento e aggiornamento delle informazioni sui clienti nel sistema gestionale.\n- Controllo e verifica dei veicoli al momento della consegna e del ritiro.\n- Supervisione del processo di fatturazione e riscossione dei pagamenti.\n- Collaborazione con il team per migliorare continuamente l'esperienza del cliente.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 171,
      nome: "Daniele ",
      cognome: "Farina",
      email: "danielefarina",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 122,
      nome: "Kevin",
      cognome: "Feige",
      email: "kevin@hollywood.it",
      temporaryPassword: "&A8sP3fJ",
      avatar_url:
        "https://api-demo.whappy.it/Drive/f94dc516-66bc-4795-b6cb-39092475c2e2_Schermata%202022-07-04%20alle%2019.47.19.png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 18,
      id_area: 14,
      id_department: 9,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 90,
      role: null,
      permalink: "kevin@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 185,
      nome: "Iandiorio ",
      cognome: "Felici",
      email: "iandioriofelici",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 177,
      nome: "Eliana ",
      cognome: "Felici ",
      email: "elianafelici",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:53:34.443",
    },
    {
      id: 169,
      nome: "Chiara ",
      cognome: "Foialdelli ",
      email: "chiarafoialdelli",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 194,
      nome: "Niccolò  ",
      cognome: "Franco",
      email: "niccolòfranco",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 197,
      nome: "Paolo ",
      cognome: "Franco",
      email: "paolofranco",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 97,
      nome: "nuovo",
      cognome: "frontend",
      email: "nuovo.frontend@whappy.it",
      temporaryPassword: "OJYyUGbV",
      avatar_url:
        "https://api-demo.whappy.it/Drive/4900d615-e93f-46c6-b1f4-7ec09a7b26ce_Barton-Perreira.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 17,
      id_area: 12,
      id_department: null,
      id_cluster: null,
      company: null,
      id_parent: 94,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 84,
      role: null,
      permalink: "nuovo.frontend@whappy.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2022-07-29T12:31:28.723",
    },
    {
      id: 222,
      nome: "Federica",
      cognome: "Fusco\r\n",
      email: "federicafusco",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:50:18.283",
    },
    {
      id: 191,
      nome: "Marina ",
      cognome: "Gargantini ",
      email: "marinagargantini",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:50:47.477",
    },
    {
      id: 153,
      nome: "Adriano",
      cognome: "Gazzerro",
      email: "adriano.gazzerro@whappy.it",
      temporaryPassword: "1hwJNRC8",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 17,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 94,
      role: null,
      permalink: "adriano.gazzerro@whappy.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 168,
      nome: "Cesare ",
      cognome: "Grasseni",
      email: "cesaregrasseni",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 157,
      nome: "Alessandro Dino ",
      cognome: "Guida",
      email: "alessandrodinoguida",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:50:06.587",
    },
    {
      id: 236,
      nome: "Gene",
      cognome: "Hackman",
      email: "gene.hackman@automotive.spa",
      temporaryPassword: "glIMe3aC",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/d6175d6e-b511-4c01-8b18-0b250ba804f0_Schermata%202024-08-28%20alle%2001.18.49.png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 40,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 228,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 126,
      role: null,
      permalink: "gene.hackman@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 228,
      nome: "Tom",
      cognome: "Hanks",
      email: "tom.hanks@automotive.spa",
      temporaryPassword: "ccRYib@5",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/2d86856f-2755-4590-8a9f-3f550eaa1bb8_image%20(2).png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 40,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 225,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 131,
      role: null,
      permalink: "tom.hanks@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 111,
      nome: "Anne ",
      cognome: "Hathaway",
      email: "Anne @hollywood.it",
      temporaryPassword: "l@iy0HGk",
      avatar_url:
        "https://api-demo.whappy.it/Drive/2453a190-b571-4eae-a4aa-f58333f21562_Schermata%202022-07-04%20alle%2021.28.07.png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 18,
      id_area: 14,
      id_department: 0,
      id_cluster: 5,
      company: null,
      id_parent: 121,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 88,
      role: null,
      permalink: "Anne @hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 127,
      nome: "Ben",
      cognome: "Hecht ",
      email: "ben@hollywood.it",
      temporaryPassword: "O4MNyvqh",
      avatar_url:
        "https://api-demo.whappy.it/Drive/e57e7e70-bc51-4d91-905c-74fb596e5a61_52014.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 18,
      id_area: 14,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 121,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 91,
      role: null,
      permalink: "ben@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 227,
      nome: "Dustin",
      cognome: "Hoffman",
      email: "dustin.hoffman@automotive.spa",
      temporaryPassword: "0zJ9SE5X",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/b6071098-4e59-4149-a6e0-b9e3a04d3bf6_50_mejores_actores_vivos_gq_919623350.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 40,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 225,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 130,
      role: null,
      permalink: "dustin.hoffman@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 166,
      nome: "Carla ",
      cognome: "Iozzino ",
      email: "carlaiozzino",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 109,
      nome: "Scarlett",
      cognome: "Johansson’s",
      email: "scarlett@hollywood.it",
      temporaryPassword: "T7CcLP4U",
      avatar_url:
        "https://api-demo.whappy.it/Drive/8fd8bc6a-fa22-44af-99fb-b17fca69fe9a_Scarlett-Johansson’s-Film-“Tangerine”-to-Be-Shot-in-Morocco.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 18,
      id_area: 15,
      id_department: 9,
      id_cluster: 5,
      company: null,
      id_parent: 117,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 88,
      role: null,
      permalink: "scarlett@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 103,
      nome: "Angelina",
      cognome: "Jolie",
      email: "angelina@hollywood.it",
      temporaryPassword: "z9U2zNV6",
      avatar_url:
        "https://api-demo.whappy.it/Drive/85405398-63cb-47fd-adab-5a446d017fc1_Schermata%202022-07-04%20alle%2021.27.19.png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 18,
      id_area: 14,
      id_department: 0,
      id_cluster: 7,
      company: null,
      id_parent: 121,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 88,
      role: null,
      permalink: "angelina@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 123,
      nome: "Kathleen",
      cognome: "Kennedy",
      email: "kathleen@hollywood.it",
      temporaryPassword: "4uZlTRj7",
      avatar_url:
        "https://api-demo.whappy.it/Drive/f7cadf7a-10e1-4250-8726-fd309de741da_OIP.jpeg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 29,
      id_area: 38,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 119,
      role: null,
      permalink: "kathleen@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 247,
      nome: "Ben",
      cognome: "Kinglsey",
      email: "ben.kinglsey@automotive.spa",
      temporaryPassword: "rntDuug&",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/a3fbcd41-6c38-47ef-a882-36b2b7a9c81a_50_mejores_actores_vivos_gq_413790053.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 43,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 245,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 124,
      role: null,
      permalink: "ben.kinglsey@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT":
          "L'accettatore in un'officina Mercedes ha la missione di garantire un'accoglienza professionale ed efficiente per tutti i clienti, assicurando che le loro esigenze siano soddisfatte e che le operazioni di manutenzione e riparazione siano gestite con la massima precisione e trasparenza, in linea con gli elevati standard del marchio Mercedes-Benz.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT":
          "L'accettatore è responsabile di accogliere i clienti, identificare le loro esigenze e preparare le richieste di intervento. Deve assicurarsi che le informazioni siano correttamente trasmesse ai tecnici, coordinare le tempistiche delle lavorazioni e garantire una comunicazione continua e chiara con il cliente, mantenendo al contempo un elevato livello di soddisfazione.\n\nCompiti\n- Accogliere i clienti e ascoltare le loro richieste di servizio.\n- Redigere e gestire le richieste di lavoro in modo accurato.\n- Coordinare con il team tecnico per l'assegnazione dei compiti e il rispetto delle scadenze.\n- Tenere i clienti aggiornati sullo stato di avanzamento dei lavori.\n- Gestire le pratiche amministrative correlate agli interventi.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT":
          "- Prenotazione degli appuntamenti e gestione del flusso di lavoro giornaliero.\n- Inserimento e aggiornamento delle informazioni sui clienti nel sistema gestionale.\n- Controllo e verifica dei veicoli al momento della consegna e del ritiro.\n- Supervisione del processo di fatturazione e riscossione dei pagamenti.\n- Collaborazione con il team per migliorare continuamente l'esperienza del cliente.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 119,
      nome: "Stanley",
      cognome: "Kubrick",
      email: "stanley@hollywood.it",
      temporaryPassword: "vtjtyYoj",
      avatar_url:
        "https://api-demo.whappy.it/Drive/d43cbad5-425b-451b-ac50-9f39dd018bbc_Kubrick_on_the_set_of_Barry_Lyndon_(1975_publicity_photo)_(cropped).jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 18,
      id_area: 14,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 122,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 89,
      role: null,
      permalink: "stanley@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 161,
      nome: "Angela ",
      cognome: "Laurino",
      email: "angelalaurino",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 239,
      nome: "John",
      cognome: "Lithgow",
      email: "john.lithgow@automotive.spa",
      temporaryPassword: "qzfmngvX",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/ac4757e4-9369-43f9-95cc-62adab6c4710_50_mejores_actores_vivos_gq_588934083.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 40,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 237,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 133,
      role: null,
      permalink: "john.lithgow@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 175,
      nome: "Elena ",
      cognome: "Lotti ",
      email: "elenalotti",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 218,
      nome: "Mauro",
      cognome: "Lunghi",
      email: "maurolunghi",
      temporaryPassword: " ",
      avatar_url: " ",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: null,
      id_department: null,
      id_cluster: null,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: null,
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 186,
      nome: "Laura ",
      cognome: "Marcucci ",
      email: "lauramarcucci",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 224,
      nome: "Massimo",
      cognome: "Masiero",
      email: "demo@templari.spa",
      temporaryPassword: "icgTgVNy",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/4990060c-7347-46b1-88d5-d636b5f5001c_image.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 29,
      id_area: 37,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 119,
      role: null,
      permalink: "demo@templari.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2024-10-01T15:40:31.31",
    },
    {
      id: 165,
      nome: "Barbara ",
      cognome: "Masserelli ",
      email: "barbaramasserelli",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:49:23.53",
    },
    {
      id: 214,
      nome: "Demo",
      cognome: "Masterclass",
      email: "demo@masterclass.it",
      temporaryPassword: "kvztmV5y",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 27,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 110,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 126,
      nome: "Robert mckee ",
      cognome: "Mckee ",
      email: "robert@hollywood.it",
      temporaryPassword: "IQbUjoW3",
      avatar_url:
        "https://api-demo.whappy.it/Drive/856cccb8-3f3c-47b2-988d-8ba4b8f2fa83_robert-mckee.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 18,
      id_area: 14,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 119,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 91,
      role: null,
      permalink: "robert@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 203,
      nome: "Roberto ",
      cognome: "Melcarne",
      email: "robertomelcarne",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 113,
      nome: "Eva ",
      cognome: "Mendes",
      email: "eva@whappy.it",
      temporaryPassword: "+LkRTuJb",
      avatar_url:
        "https://api-demo.whappy.it/Drive/60b958cc-4901-47c0-bfe1-7ac5291f6532_Schermata%202022-07-04%20alle%2019.21.51.png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 18,
      id_area: 15,
      id_department: 9,
      id_cluster: 5,
      company: null,
      id_parent: 117,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 88,
      role: null,
      permalink: "eva@whappy.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 167,
      nome: "Carlo ",
      cognome: "Nasso",
      email: "carlonasso",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 225,
      nome: "Jack",
      cognome: "Nicholson",
      email: "jack.nicholson@automotive.spa",
      temporaryPassword: "lnkTLg8l",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/30ad8db1-fcd1-465c-97e9-78e853ca53a7_50_mejores_actores_vivos_gq_401591510.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 40,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 122,
      role: null,
      permalink: "jack.nicholson@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 207,
      nome: "Sheila ",
      cognome: "Nozza ",
      email: "sheilanozza",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:49:07.64",
    },
    {
      id: 226,
      nome: "Al",
      cognome: "Pacino",
      email: "al.pacino@automotive.spa",
      temporaryPassword: "lmjyf1D&",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/f68f1d85-f1ad-4919-b796-2551df399a3d_actors_22100_642225.jpeg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 40,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 225,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 128,
      role: null,
      permalink: "al.pacino@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 94,
      nome: "Responsabile",
      cognome: "Padova",
      email: "responsabile.padova@whappy.it",
      temporaryPassword: "5Lpbifj@",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 17,
      id_area: 12,
      id_department: null,
      id_cluster: null,
      company: null,
      id_parent: 7,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 85,
      role: null,
      permalink: "responsabile.padova@whappy.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 95,
      nome: "Developer",
      cognome: "Padova",
      email: "developer.padova@whappy.it",
      temporaryPassword: "$Krq$Iz9",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 17,
      id_area: 12,
      id_department: null,
      id_cluster: null,
      company: null,
      id_parent: 94,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 84,
      role: null,
      permalink: "developer.padova@whappy.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 96,
      nome: "Developer2",
      cognome: "Padova",
      email: "developer2.padova@whappy.it",
      temporaryPassword: "&Ux#kkUb",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 17,
      id_area: 12,
      id_department: null,
      id_cluster: null,
      company: null,
      id_parent: 94,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 83,
      role: null,
      permalink: "developer2.padova@whappy.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 179,
      nome: "Fabio ",
      cognome: "Parolini ",
      email: "fabioparolini",
      temporaryPassword: "",
      avatar_url:
        "https://api-demo.whappy.it/Drive/c976ad48-7321-421d-a8b3-32befe1d20f7_image.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:51:40.94",
    },
    {
      id: 181,
      nome: "Felice ",
      cognome: "Parolini ",
      email: "feliceparolini",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:49:40.847",
    },
    {
      id: 158,
      nome: "Alessio ",
      cognome: "Pedrali",
      email: "alessiopedrali",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:49:17.737",
    },
    {
      id: 204,
      nome: "Sabrina ",
      cognome: "Perrone ",
      email: "sabrinaperrone",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 164,
      nome: "Armando ",
      cognome: "Persico",
      email: "armandopersico",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:49:53.883",
    },
    {
      id: 238,
      nome: "Brad",
      cognome: "Pitt",
      email: "brad.pitt@automotive.spa",
      temporaryPassword: "sHleoJBg",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/800c6e62-9e1b-49f7-b9fa-812c83d4dc9f_50_mejores_actores_vivos_gq_81210731.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 42,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 237,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 133,
      role: null,
      permalink: "brad.pitt@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 234,
      nome: "Sidney",
      cognome: "Poitier",
      email: "sidney.poitier@automotive.spa",
      temporaryPassword: "xRWFfkfz",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/9fd5134e-9995-4981-ac34-f4fcf9cc8ce8_50_mejores_actores_vivos_gq_839365637.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 42,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 232,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 124,
      role: null,
      permalink: "sidney.poitier@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT":
          "L'accettatore in un'officina Mercedes ha la missione di garantire un'accoglienza professionale ed efficiente per tutti i clienti, assicurando che le loro esigenze siano soddisfatte e che le operazioni di manutenzione e riparazione siano gestite con la massima precisione e trasparenza, in linea con gli elevati standard del marchio Mercedes-Benz.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT":
          "L'accettatore è responsabile di accogliere i clienti, identificare le loro esigenze e preparare le richieste di intervento. Deve assicurarsi che le informazioni siano correttamente trasmesse ai tecnici, coordinare le tempistiche delle lavorazioni e garantire una comunicazione continua e chiara con il cliente, mantenendo al contempo un elevato livello di soddisfazione.\n\nCompiti\n- Accogliere i clienti e ascoltare le loro richieste di servizio.\n- Redigere e gestire le richieste di lavoro in modo accurato.\n- Coordinare con il team tecnico per l'assegnazione dei compiti e il rispetto delle scadenze.\n- Tenere i clienti aggiornati sullo stato di avanzamento dei lavori.\n- Gestire le pratiche amministrative correlate agli interventi.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT":
          "- Prenotazione degli appuntamenti e gestione del flusso di lavoro giornaliero.\n- Inserimento e aggiornamento delle informazioni sui clienti nel sistema gestionale.\n- Controllo e verifica dei veicoli al momento della consegna e del ritiro.\n- Supervisione del processo di fatturazione e riscossione dei pagamenti.\n- Collaborazione con il team per migliorare continuamente l'esperienza del cliente.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 245,
      nome: "Robert",
      cognome: "Redford",
      email: "robert.redford@automotive.spa",
      temporaryPassword: "gRx3Nr-p",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/7aad728d-5b47-4737-8de7-bb18a7ff8681_50_mejores_actores_vivos_gq_642598255.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 43,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 228,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 126,
      role: null,
      permalink: "robert.redford@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 242,
      nome: "Geoffrey",
      cognome: "Rush",
      email: "geoffrey.rush@automotive.spa",
      temporaryPassword: "PQmQrDP7",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/e59b0bfb-8597-4e59-ad74-251cf6815de3_50_mejores_actores_vivos_gq_198872743.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 41,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 228,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 126,
      role: null,
      permalink: "geoffrey.rush@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 201,
      nome: "Rino ",
      cognome: "Russo ",
      email: "rinorusso",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 219,
      nome: "Sude",
      cognome: "Saricimen",
      email: "sudesaricimen",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 211,
      nome: "Sude ",
      cognome: "Saricimen ",
      email: "sudesaricimen",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 121,
      nome: "Ridley",
      cognome: "Scott",
      email: "ridley@hollywood.it",
      temporaryPassword: "1CC#DCT0",
      avatar_url:
        "https://api-demo.whappy.it/Drive/04ad9ce6-16f4-40ff-918f-f783dd1ebb3c_Schermata%202022-07-04%20alle%2019.43.06.png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 18,
      id_area: 14,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 122,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 89,
      role: null,
      permalink: "ridley@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 183,
      nome: "Giuseppe ",
      cognome: "Scribani",
      email: "giuseppescribani",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:50:14.28",
    },
    {
      id: 199,
      nome: "Patrizia ",
      cognome: "Scribani",
      email: "patriziascribani",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 206,
      nome: "Sara ",
      cognome: "Serafini ",
      email: "saraserafini",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:49:08.01",
    },
    {
      id: 196,
      nome: "Nicolò ",
      cognome: "Spinelli ",
      email: "nicolòspinelli",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:49:20.58",
    },
    {
      id: 176,
      nome: "Eleonora ",
      cognome: "Tafuri ",
      email: "eleonoratafuri",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 51,
      nome: "Utente ",
      cognome: "Test",
      email: "test@Whappy.it",
      temporaryPassword: "Kt8EF5@3",
      avatar_url:
        "https://api-trivellato.whappy.it/Drive/919f3189-830b-4db4-922d-8bdfc1880515_images.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 18,
      id_area: 11,
      id_department: null,
      id_cluster: null,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 88,
      role: null,
      permalink: "test@Whappy.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-09-28T14:16:21.933",
    },
    {
      id: 180,
      nome: "Fabio ",
      cognome: "Toffoli ",
      email: "fabiotoffoli",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 220,
      nome: "Dario",
      cognome: "Valli",
      email: "dariovalli",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:51:55.133",
    },
    {
      id: 182,
      nome: "Francesco ",
      cognome: "Valli ",
      email: "francescovalli",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:50:51.26",
    },
    {
      id: 160,
      nome: "Andrea ",
      cognome: "Valli ",
      email: "andreavalli",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:49:31.707",
    },
    {
      id: 200,
      nome: "Pierzaverio ",
      cognome: "Valli ",
      email: "pierzaveriovalli",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 198,
      nome: "Paolo ",
      cognome: "Valli ",
      email: "paolovalli",
      temporaryPassword: "",
      avatar_url:
        "https://api-demo.whappy.it/Drive/50d3e7dd-eada-4f7e-94c3-1524fd40c8c3_image.jpg",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2024-04-03T15:12:55.153",
    },
    {
      id: 188,
      nome: "Luca ",
      cognome: "Valli ",
      email: "lucavalli",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:49:13.04",
    },
    {
      id: 162,
      nome: "Anna ",
      cognome: "Valli ",
      email: "annavalli",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:48:48.68",
    },
    {
      id: 159,
      nome: "Alice ",
      cognome: "Viscardi",
      email: "aliceviscardi",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:48:51.627",
    },
    {
      id: 240,
      nome: "Jon",
      cognome: "Voight",
      email: "jon.voight@automotive.spa",
      temporaryPassword: "e*L5dQa3",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/c3a6311f-7afd-462e-9f95-81e94c04f47d_50_mejores_actores_vivos_gq_808201255.webp",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 40,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 236,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 124,
      role: null,
      permalink: "jon.voight@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT":
          "L'accettatore in un'officina Mercedes ha la missione di garantire un'accoglienza professionale ed efficiente per tutti i clienti, assicurando che le loro esigenze siano soddisfatte e che le operazioni di manutenzione e riparazione siano gestite con la massima precisione e trasparenza, in linea con gli elevati standard del marchio Mercedes-Benz.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT":
          "L'accettatore è responsabile di accogliere i clienti, identificare le loro esigenze e preparare le richieste di intervento. Deve assicurarsi che le informazioni siano correttamente trasmesse ai tecnici, coordinare le tempistiche delle lavorazioni e garantire una comunicazione continua e chiara con il cliente, mantenendo al contempo un elevato livello di soddisfazione.\n\nCompiti\n- Accogliere i clienti e ascoltare le loro richieste di servizio.\n- Redigere e gestire le richieste di lavoro in modo accurato.\n- Coordinare con il team tecnico per l'assegnazione dei compiti e il rispetto delle scadenze.\n- Tenere i clienti aggiornati sullo stato di avanzamento dei lavori.\n- Gestire le pratiche amministrative correlate agli interventi.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT":
          "- Prenotazione degli appuntamenti e gestione del flusso di lavoro giornaliero.\n- Inserimento e aggiornamento delle informazioni sui clienti nel sistema gestionale.\n- Controllo e verifica dei veicoli al momento della consegna e del ritiro.\n- Supervisione del processo di fatturazione e riscossione dei pagamenti.\n- Collaborazione con il team per migliorare continuamente l'esperienza del cliente.",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 231,
      nome: "Denzel",
      cognome: "Washington",
      email: "denzel.washington@automotive.spa",
      temporaryPassword: "$MN9wCPh",
      avatar_url:
        "https://api-automotive-demo.whappy.it/Drive/95113035-2d1f-4fb4-b8d6-cc577cddd117_Schermata%202024-08-28%20alle%2001.25.01.png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 30,
      id_area: 42,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 229,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 125,
      role: null,
      permalink: "denzel.washington@automotive.spa",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 116,
      nome: "Denzel",
      cognome: "Washington",
      email: "denzel@hollywood.it",
      temporaryPassword: "00uM6@B8",
      avatar_url:
        "https://api-demo.whappy.it/Drive/69f64a2b-87da-4b32-9061-afeecdb0d688_Schermata%202022-07-04%20alle%2019.31.10.png",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 18,
      id_area: 15,
      id_department: 9,
      id_cluster: 7,
      company: null,
      id_parent: 117,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 88,
      role: null,
      permalink: "denzel@hollywood.it",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 208,
      nome: "Simone ",
      cognome: "Zampatti ",
      email: "simonezampatti",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:48:51.603",
    },
    {
      id: 190,
      nome: "Maria Teresa ",
      cognome: "Zappella ",
      email: "maria teresazappella",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 221,
      nome: "Ezra Riu",
      cognome: "Zucchelli",
      email: "ezrariuzucchelli",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: null,
    },
    {
      id: 205,
      nome: "Sabrina ",
      cognome: "Zucchelli ",
      email: "sabrinazucchelli",
      temporaryPassword: "",
      avatar_url: "",
      social: { 2: "", 3: "", 4: "", 5: "", 6: "", 7: "" },
      id_company: 26,
      id_area: 0,
      id_department: 0,
      id_cluster: 0,
      company: null,
      id_parent: 0,
      id_livello: 0,
      livello: null,
      livello_img: null,
      id_role: 109,
      role: null,
      permalink: "",
      role_nome: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_mission: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_respcompiti: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      role_attivita: {
        "it-IT": "",
        "en-EN": "",
        "es-ES": "",
        "de-DE": "",
        "fr-FR": "",
        "ml-ML": "",
        "cn-CN": "",
      },
      target: 0,
      attivo: true,
      NewPasswordRequired: false,
      regioniEC: null,
      dataPrivacy: "2023-10-27T19:52:08.3",
    },
  ]);

  const _selectedUser = ref(
    JSON.parse(localStorage.getItem("selectedUser")) || 159
  );
  const _currentSurvey = ref(
    JSON.parse(localStorage.getItem("currentSurvey")) || {}
  );

  const _isAccademy = ref(false);
  const _isOnboarding = ref(false);
  const _accademyPath = ref("");

  const youbeFilter = computed(() => _youbeFilter.value);
  const userList = computed(() => {
    if (!_userList.value.length) getUsers();
    return _userList.value;
  });
  const selectedUser = computed(() => _selectedUser.value);
  const currentSurvey = computed(() => _currentSurvey.value);

  const isAccademy = computed(() => _isAccademy.value);
  const isOnboarding = computed(() => _isOnboarding.value);
  const accademyPath = computed(() => _accademyPath.value);

  function setIsAccademy(value) {
    _isAccademy.value = value;
  }
  function setIsOnboarding(value) {
    _isOnboarding.value = value;
  }

  function setYoubeFilter(filter) {
    _youbeFilter.value = filter;
  }

  function resetGeneralFilter() {
    _youbeFilter.value = {
      regione: "all",
      provincia: "all",
      citta: "all",
      agente: null,
    };
  }

  async function getUsers() {
    try {
      const res = await ApiService.get("User");
      _userList.value = res.data;
    } catch (error) {
      console.error("ERR - pinia - getUsers: ", error);
    }
  }

  function setSelectedUser(user) {
    _selectedUser.value = user;
  }

  function setCurrentSurvey(survey) {
    _currentSurvey.value = survey;
    localStorage.setItem("currentSurvey", JSON.stringify(survey));
  }

  function setAccademyPath(value) {
    _accademyPath.value = value;
  }

  // Watcher per salvare i dati nel localStorage quando vengono modificati
  watch(
    _youbeFilter,
    (newFilter) => {
      localStorage.setItem("youbeFilter", JSON.stringify(newFilter));
    },
    { deep: true }
  );

  watch(_selectedUser, (newUser) => {
    localStorage.setItem("selectedUser", JSON.stringify(newUser));
  });

  return {
    loading,
    youbeFilter,
    setYoubeFilter,
    resetGeneralFilter,
    userList,
    getUsers,
    selectedUser,
    setSelectedUser,
    currentSurvey,
    setCurrentSurvey,

    _isAccademy,
    isAccademy,
    setIsAccademy,

    _accademyPath,
    accademyPath,
    setAccademyPath,

    _isOnboarding,
    isOnboarding,
    setIsOnboarding,
  };
});
