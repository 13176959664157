import { createStore } from "vuex";
import AuthModule from "./modules/auth";
import LoadingModule from "./modules/loading";
import CampaignsModule from "./modules/campaigns";
import UserModule from "./modules/user";
import shoppingCart from "./modules/shoppingCart";
import generalStore from "./modules/generalStore";
import talk from "./modules/talk";

const store = createStore({
  modules: {
    auth: AuthModule,
    loading: LoadingModule,
    campaigns: CampaignsModule,
    user: UserModule,
    shoppingCart: shoppingCart,
    generalStore: generalStore,
    talk: talk,
  },
});

export default store;
